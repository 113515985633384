@media print {
  @page {
    size: A4;
    margin: 5mm auto 0;
  }

  .page-break {
    height: 0;
    page-break-before: always;
    padding: 5mm 0 0;
    border-top: none;
  }
}

.cv {
  background: $colorWhite;
  padding: 3mm 10mm 10mm;
  font-family: $fontRoboto;
  font-size: 11px;
  line-height: 16px;
  width: 21cm;
  min-height: 29cm;
  margin: 0 auto;
  -webkit-print-color-adjust: exact;

  /* change the margins as you want them to be. */
  p {
    margin-bottom: 2px;
  }

  .content {
    display: flex;
  }

  .column {
    padding: 0 3mm;
  }

  .column-third {
    flex: 1 1 0;
  }

  .column-twothird {
    flex: 2.9 1 0;
  }

  h1 {
    font-size: 44px;
    line-height: 42px;
    font-family: $fontRubik;
    font-weight: 900;
  }

  h2 {
    margin: 0;
    text-transform: none;
    font-size: 28px;
    line-height: 40px;
    font-weight: 300;
    font-family: $fontRubik;
    margin-bottom: 6px;
  }

  h3 {
    margin: 20px 0 $layoutSpaceHalf;
    font-family: $fontRubik;
    font-weight: 700;
    border-bottom: 1px solid $fontColor;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
  }

  h4 {
    font-family: $fontRubik;
    font-weight: 700;
    text-transform: none;
    margin: 10px 0 4px;
    font-size: 12px;

    span {
      width: 1em;
      margin-right: 10px;
    }
  }

  header {
    background-color: $colorWhite;
    padding: $layoutSpace 3mm 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    h4 {
      font-family: $fontRubik;
      font-weight: 300;
      text-transform: none;
      font-size: 15px;
      line-height: 19px;
      color: $fontColor;
      margin-top: 4px;
    }
  }

  aside {
    h4 {
      color: $colorHighlight;
    }

    order: -1;
  }

  footer {
    background-color: $colorWhite;
    height: 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    font-size: 7px;

    p {
      margin: 0 auto;
      width: 21cm;
      text-align: center;
      padding: 5px 15mm;
    }
  }

  .intro {
    padding: 0 3mm;
    font-size: 12px;
  }

  .project-tags {
    margin-top: 3px;
    padding-left: 21px;
    position: relative;
    font-weight: 300;

    span {
      position: absolute;
      top: 1px;
      left: 0;
    }
  }

  .tagline {
    justify-content: flex-start;

    li {
      background-color: transparent;
      border-color: $colorMuteCV;
      margin: 3px;
      padding: 4px;
    }
  }

  .textbox {
    a {
      font-weight: 600;
    }

    h5 {
      margin-top: 15px;
    }

    .cv-link {
      font-size: 0.8em;
    }

    ul {
      display: block;

      li {
        padding: 0;
        margin: 0 0 2px 13px;
        list-style: square;

        +li {
          margin-left: 13px;
        }
      }
    }
  }

  ul.contact-items-header {
    flex-flow: column wrap;
    font-size: 10px;
    line-height: 16px;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: $fontRubik;
    font-weight: 300;

    >li {
      padding: 4px 0;
    }

    a,
    p {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 16px;
      line-height: 20px;
      order: 1;
      margin-left: 10px;
    }
  }

  .information {
    font-family: $fontRubik;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    margin-bottom: 4px;

    p+p {
      margin-left: 20px;
    }

    span {
      margin-right: 5px;
      color: $colorMuteCV;
    }
  }

  .technologies.fas {
    margin-right: 5px;
    font-size: 14px;
    color: $colorMuteCV, ;
  }
}