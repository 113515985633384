@mixin animation ($name, $delay, $duration, $iteration) {
  animation-name: $name;
  animation-delay: $delay;
  animation-duration: $duration;
  animation-iteration-count: $iteration;
}

@keyframes imageFader {
  0% { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  90% { opacity: 1}
  100% { opacity: 0; }
}

@keyframes fadeIn {
  0% {
    filter:blur(5px);
 }
  100% {
    filter:blur(0px);
  }
}
@keyframes fadeOut {
  0% {
    opacity: 0.5;
 }
  100% {
    opacity: 0;
  }
}
