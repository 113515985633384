.contact {
  display: flex;
  flex-direction: column;
  h3 {
    margin: 20px 0 0;
    @media screen and (min-width: 768px) {
      margin-top: 60px;
    }
  }
}

.contact-list {
  margin: 10px 0;
  @media screen and (min-width: 768px) {
    margin: 20px 0;
  }
}

.contact-list li a {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
}
.contact-list li a [class^="icon-"],
.contact-list li a [class*=" icon-"] {
  font-size: 36px;
  line-height: 50px;
  @media screen and (min-width: 768px) {
    font-size: 40px;
  }
}
