.projects {
  max-width: 1200px;
}

.project-wrapper {
  @extend .box-wrapper;
}

.project {
  overflow: hidden;
  @extend .box;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
  }

  @media screen and (min-width: 1024px) {
    width: calc(33.33% - 20px); // IE10 Bugfix
    flex: 0 0 calc(33.33% - 20px);
  }
}

.project-image {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: #F6F6F5;
  color: #F6F6F5;

  img {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &.animated {
    img+img {
      opacity: 0;
      @include animation(imageFader, 0s, 8s, indefinite)
    }
  }
}

.project-info {
  margin-bottom: $layoutSpaceHalf;
}

.project-highlight {
  margin-bottom: $layoutSpaceHalf;
  text-transform: uppercase;
}

.project-text {
  padding: 5px;

  @media screen and (min-width: 768px) {
    padding: $layoutSpaceHalf;
  }

  h4 {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }
}

.project-details {
  flex: 1 0 auto;
}

.project-footer {
  margin: $layoutSpaceHalf;
}

.tagline {
  font-size: 9px;
  line-height: 100%;
  flex-wrap: wrap;
  align-items: flex-start;

  >li {
    padding: 5px;
    background-color: $colorLight;
    border: 1px solid $colorBlack;
    border-radius: 5px;
    margin: 3px;
  }

  +div {
    margin-top: 5px;
  }
}