.timeline {
  margin: 0 0 $layoutSpaceHalf;
}

.timeline-box {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: left;

  @media screen and (min-width: 768px),
  print {
    h4 {
      color: $fontColor;
      margin-top: 8px;
    }

    flex: 0 1 auto;
    flex-direction: row;

    &:before {
      display: block;
    }
  }

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $colorHighlight;
    position: absolute;
    top: 3px;
    left: 0;

    @media screen and (min-width: 768px),
    print {
      left: 115px;
      top: 10px;
    }
  }

  &:after {
    border-left: 6px solid $colorHighlight;
    position: absolute;
    left: 5px;
    top: 11px;
    height: 100%;
    content: '';

    @media screen and (min-width: 768px),
    print {
      left: 120px;
    }
  }

  &:last-child:after {
    top: 0;
  }

  .textbox {
    padding: 0 0 0 30px;

    @media screen and (min-width: 768px),
    print {
      width: 100%;
      padding-left: 14px;
    }

    p {
      word-break: normal;
    }
  }

  .timestamp {
    margin: 0 0 0 30px;
    font-weight: 700;
    font-family: $fontRubik;

    @media screen and (min-width: 768px),
    print {
      padding-right: 15px;
      text-align: right;
      width: 148px;
      margin: $layoutSpaceHalf 0 5px;
    }
  }
}