body {
  background-color: $bodyBgColor;
  color: $fontColor;
  font-family: $fontFamiliyBase;
  font-size: $fontSizeMobile;
  line-height: $lineHeightMobile;
  min-width: 320px;

  @media screen and (min-width: 768px) {
    font-size: $fontSizeBase;
    line-height: $lineHeightBase;
  }
}

.page {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

.page-header {
  text-align: center;
  height: $headerHeightMobile;
  background-color: $bodyBgColor;
  position: relative;
  z-index: 200;
  padding: $layoutSpace $layoutSpaceHalf;

  @media screen and (min-width: 768px) {
    padding: $layoutSpaceDouble $layoutSpace $layoutSpace;
    height: $headerHeight;
  }

  h2 {
    font-size: 26px;
    line-height: 30px;
    margin: 20px 0;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      font-size: 40px;
      line-height: 58px;
    }
  }

  a {
    &:hover {
      color: $fontColor;

      &:active,
      &:focus,
      &:visited {
        color: $fontColor;
      }
    }

    &.active {
      pointer-events: none;
    }
  }
}

.page-content {
  background-color: $bodyBgColor;
  width: 100%;
  text-align: center;
  position: relative;
}

.page-content {
  flex-grow: 1;
}

.content-wrapper {
  max-width: 1024px;
  padding: $layoutSpaceHalf $layoutSpace $layoutSpace;

  @media screen and (min-width: 768px) {
    padding: $layoutSpace $layoutSpace 30px;
    margin: 0 auto;
  }
}

.page-footer {
  background-color: $bodyBgColor;
  height: 60px;
  padding: 0 $layoutSpace;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2,
h3,
h4 {
  font-weight: normal;
}

h1 {
  color: $fontColor;
  font-family: $fontFamilyHighlight;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 5px;

  @media screen and (min-width: 768px) {
    font-size: 60px;
    line-height: 72px;
  }
}

h2 {
  font-size: 20px;
  line-height: 26px;
  margin: 40px 0 20px;

  &:first-child {
    margin-top: $layoutSpace;
  }

  @media screen and (min-width: 768px) {
    font-size: 30px;
    line-height: 36px;
  }

  .page-header & {
    margin: 0;
    font-size: 26px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: $fontFamiliyBase;

    @media screen and (min-width: 768px) {
      font-size: 40px;
      line-height: 58px;
    }
  }
}

h3 {
  text-transform: uppercase;
  margin: $layoutSpace 0;
  font-size: 1.11em;
}

h4 {
  text-transform: uppercase;
  margin: 5px 0;
}

a,
a:active,
a:focus,
a:visited {
  color: $fontColor;
  text-decoration: none;
  transition: color 200ms ease-out;

  &:hover {
    color: $colorHighlight;
    transition: color 200ms ease-out;
  }
}

p {
  margin-bottom: 0.5em;
}

ul {
  align-items: center;
  display: flex;
  list-style: none;
  justify-content: center;

  >li {
    padding: 10px 0;
    position: relative;

    +li {
      margin-left: 10px;
    }
  }

  &.list-bullets {
    flex-direction: row;
    flex-wrap: wrap;

    li {
      flex: 0 0 100%;
    }
  }
}

a.button {
  padding: $layoutSpaceHalf $layoutSpace;
  background-color: $colorBlack;
  color: $colorLight;
  display: inline-block;
  border-radius: 2px;
  margin: 5px;
  font-size: 0.9em;
  cursor: pointer;

  &:active,
  &:hover {
    color: $colorHighlight;
  }

  @media screen and (min-width: 768px) {
    font-size: 1em;
  }
}

.box-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
  justify-content: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -1%;
  }
}

.box {
  margin-bottom: $layoutSpace;
  border: 1px solid $fontColor;
  background-color: $colorWhite;
  font-size: 14px;
  line-height: 20px;

  @media screen and (min-width: 768px) {
    margin: 10px;
    width: calc(50% - 20px); //IE10 Bugfix
    flex: 0 0 calc(50% - 20px);
  }
}

/** 404 **/
.error {
  max-width: 700px;
  text-align: center;

  h2 {
    position: relative;
    font-weight: bold;
    color: $colorHighlight;
    font-size: 80px;
    line-height: 1em;
    margin: 30px 0;
    display: block;

    @media screen and (min-width: 768px) {
      margin: 60px 0;
      font-size: 140px;
    }

    span {
      display: inline-block;
      width: 40px;
      color: $fontColor;
      font-size: 30px;
      border-bottom: 1px solid $fontColor;

      @media screen and (min-width: 768px) {
        font-size: 60px;
        width: 70px;
      }
    }
  }

  .oversized {
    font-family: $fontFamilyHighlight;
    font-size: 46px;
    line-height: 1.2em;
    margin-bottom: 50px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      margin-bottom: 80px;
      font-size: 80px;
    }
  }
}