// colors
$colorPrimary: #1D423B;
$colorSecondary: #59001C;
$colorPrimaryLight: #8BA26C;
$colorSecondaryLight: #B30908;
$colorHighlight: #eb792e;
$colorBlack: #1B141B;
$colorWhite: #ffffff;
$colorLight: #f6f6f6;
$colorDanger: #F24E4E;
$colorWarning: #F2C94E;
$colorSuccess: #178350;
$bodyBgColor: $colorWhite;
$fontColor: $colorBlack;
$linkColor: $fontColor;
$linkColorHover: $colorPrimary;
$colorMuteCV: rgba(27, 20, 27, 0.25);
/** Font Families **/
$fontRubik: 'Rubik',
Helvetica;
$fontRoboto: 'Roboto',
sans-serif;
$fontRobotoMono: 'Roboto Mono',
monospace;
$fontFamiliyBase: $fontRobotoMono;
$fontFamilyHighlight: $fontRubik;
/** font-sizes **/
$fontSizeBase: 20px;
$lineHeightBase: 32px;
$fontSizeMobile: 16px;
$lineHeightMobile: 28px;
/** Layout variables **/
$layoutSpace: 20px;
$layoutSpaceHalf: calc($layoutSpace / 2);
$layoutSpaceDouble: $layoutSpace*2;
$layoutSpaceAndHalf: $layoutSpace*1.5;
$footerHeight: 60px;
$headerHeight: 195px;
$headerHeightMobile: 125px;