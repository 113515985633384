/** ICONFONT **/

@font-face {
  font-family: 'iconfont';
  src:
    url("../fonts/iconfont.ttf?hsmduf") format('truetype'),
    url("../fonts/iconfont.woff?hsmduf") format('woff'),
    url("../fonts/iconfont.svg?hsmduf#iconfont") format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-envelope:before {
  content: "\e90c";
}

.icon-icon-home:before {
  content: "\e90d";
}

.icon-icon-screen:before {
  content: "\e90e";
}

.icon-icon-speech:before {
  content: "\e90f";
}

.icon-icon-web:before {
  content: "\e910";
}

.icon-github:before {
  content: "\e900";
}

.icon-twitter-with-circle:before {
  content: "\e901";
}

.icon-twitter:before {
  content: "\e902";
}

.icon-display:before {
  content: "\e911";
}

.icon-earth:before {
  content: "\e912";
}

.icon-bubbles:before {
  content: "\e913";
}

.icon-stickman-8:before {
  content: "\e90b";
}

.icon-stickman-7:before {
  content: "\e90a";
}

.icon-stickman-6:before {
  content: "\e908";
}

.icon-stickman-5:before {
  content: "\e907";
}

.icon-stickman-4:before {
  content: "\e906";
}

.icon-stickman-3:before {
  content: "\e905";
}

.icon-stickman-1:before {
  content: "\e903";
}

.icon-stickman-2:before {
  content: "\e904";
}

.icon-linkedin:before {
  content: "\eac9";
}

.icon-linkedin2:before {
  content: "\eaca";
}

.icon-xing:before {
  content: "\ead3";
}

.icon-xing2:before {
  content: "\ead4";
}

.icon-mark-github:before {
  content: "\f00a";
}

.icon-mail:before {
  content: "\e909";
}

.icon-link:before {
  content: "\e9cb";
}

.icon-new-tab:before {
  content: "\ea7e";
}