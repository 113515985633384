.button-projects {
  position: absolute;
  display: block;
  top: 8%;
  left: 5%;

  a.button {
    margin: 0;
    padding: 5px 10px;
  }

  @media screen and (min-width: 600px) {
    left: 15%;
    top: 10%;
    font-size: $fontSizeMobile;
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    left: 0;
    text-align: center;
    font-size: $fontSizeBase;

    a.button {
      padding: 10px 20px;
    }
  }
}

.fullscreen-image {
  position: relative;
  padding-bottom: 55%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-image: url("./../images/_B8A6691_600x2.jpg");

  @media screen and (min-width: 600px) {
    background-image: url("./../images/_B8A6691_1024x2.jpg");
  }

  @media screen and (min-width: 1024px) {
    background-image: url("./../images/_B8A6691_1600x2.jpg");
    padding-bottom: 40%;
    background-position: 100% 100%;
  }
}