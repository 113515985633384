$transition-duration: 300ms;

.content-wrapper {}

.page-enter {
  opacity: 0;
  transition: opacity $transition-duration ease-out;
  transition-delay: $transition-duration;
}

.page-enter.page-enter-active {
  opacity: 1;
}

.page-leave {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  width: 100%;
  background-color: $bodyBgColor;
}

.page-leave.page-leave-active {
  opacity: 0;
  transition: all $transition-duration linear;
}