.page-nav {
  width: 100%;
  padding-top: 17px;

  @media screen and (min-width: 768px) {
    padding-top: 23px;
  }

  .icon,
  &:after {
    font-size: 28px;
    padding-left: 10px;

    @media screen and (min-width: 768px) {
      font-size: 40px;
    }
  }

  .icon-stickman-5 {
    position: absolute;
    top: -16px;
    left: -16px;

    @media screen and (min-width: 768px) {
      top: -22px;
      left: -22px;
    }

    &:before {
      .sticky-true & {
        content: "\e90a";
      }
    }
  }

  ul {
    border-bottom: 1px solid $colorBlack;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0;

    li {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
      padding: 0;

      &:first-child .icon,
      &:last-child .icon {
        padding-left: 0;
      }

      &:first-child {
        .icon {
          transform: rotateY(180deg);
        }

        .brick:before {
          display: none;
        }
      }

      &:last-child {
        margin-left: $layoutSpace;

        .brick:after {
          display: none;
        }

        @media screen and (min-width: 768px) {
          margin-left: $layoutSpaceAndHalf;
        }
      }

      @media screen and (max-width: 767px) {
        +li {
          margin-left: 7px;
        }
      }
    }
  }
}

.page-nav .nav-link {
  position: relative;
  display: flex;
  align-items: flex-end;
  color: $colorLight;

  span {
    display: inline-block;
  }

  .icon {
    color: $fontColor;
  }

  &:hover {
    color: $colorHighlight;
  }

  &.active {
    pointer-events: none;
    color: $colorLight;

    .icon {
      color: $fontColor;
    }
  }
}

.brick {
  display: inline-block;
  background-color: $colorBlack;
  padding: 5px $layoutSpaceHalf;
  width: auto;
  height: 36px;
  line-height: 26px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14px;
  position: relative;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    padding: $layoutSpaceHalf $layoutSpace;
    height: 45px;
    line-height: 25px;
  }

  &:before,
  &:after {
    width: 8px;
    height: 12px;
    content: '';
    display: inline-block;
    position: absolute;

    @media screen and (min-width: 768px) {
      height: 15px;
      width: 11px;
    }
  }

  &:before {
    border-top: 12px solid $colorBlack;
    border-bottom: 12px solid $colorBlack;
    left: -7px;
    top: 0;

    @media screen and (min-width: 768px) {
      border-top-width: 15px;
      border-bottom-width: 15px;
      left: -10px;
    }
  }

  &:after {
    background-color: $colorBlack;
    top: 12px;
    right: -7px;

    @media screen and (min-width: 768px) {
      top: 15px;
      right: -10px;
    }
  }

  .active & {
    background-color: $colorHighlight;

    &:before {
      border-color: $colorHighlight;
    }

    &:after {
      background-color: $colorHighlight;
    }
  }
}

/** sticky nav **/
.sticky-nav {
  position: relative;
  background-color: $bodyBgColor;
  z-index: 200;
}

.page {
  margin-bottom: 0;
  transition: all 500ms ease-out;
}